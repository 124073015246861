import { ReactElement } from "react";
import styles from "./Button.module.scss";
import { clsx } from "clsx";
import { Icon } from "../Icon";

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "style"> {
  color?: "primary" | "secondary" | "tertiary" | "destructive" | "inverted";
  variant?: "solid" | "outline" | "text";
  label?: string;
  icon?: ReactElement;
  iconPos?: "left" | "right" | "both";
  blank?: boolean;
  size?: "sm" | "md";
  inProgress?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  color = "primary",
  variant = "solid",
  iconPos = "left",
  blank = false,
  size = "md",
  children,
  inProgress,
  ...props
}) => {
  return (
    <button
      {...props}
      className={clsx(
        styles["button"],
        styles[`color-${color ?? "primary"}`],
        styles[`variant-${variant ?? "solid"}`],
        styles[`size--${size ?? "md"}`],
        props.className,
        {
          [styles["blank"]]: blank,
        },
      )}
    >
      <span>
        {props?.icon && ["left", "both"]?.includes(iconPos)
          ? props?.icon
          : null}
        {props?.label ?? null}
        {!blank ? children : null}
        {props?.icon && ["right", "both"]?.includes(iconPos)
          ? props?.icon
          : null}
        {inProgress && (
          <Icon
            className={styles["progress"]}
            type="progress"
            color="inverted"
          />
        )}
      </span>
    </button>
  );
};
