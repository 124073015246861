import React, { ReactElement } from "react";
import OtpInput from "react-otp-input-rc-17";

interface IOTPInputProps {
  length?: number;
  value?: string;
  onChange?: () => void;
  disabled?: boolean;
  hasErrored?: boolean;
}

const OTPInput = ({
  length = 6,
  value,
  onChange,
  disabled = false,
  hasErrored = false,
}: IOTPInputProps): ReactElement => {
  const className = "abridge-otp-input";
  return (
    <>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={length}
        containerStyle={`${className}-container`}
        inputStyle={`${className}-main`}
        focusStyle={`${className}-focus`}
        disabledStyle={`${className}-disabled`}
        errorStyle={`${className}-error`}
        shouldAutoFocus={true}
        isInputNum={true}
        isDisabled={disabled}
        hasErrored={hasErrored}
      />
    </>
  );
};

export default OTPInput;
