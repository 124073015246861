import React, { ReactElement, useEffect, useState } from "react";
import {
  allCountryList,
  ICountrySelectData,
  LOCAL_STORAGE_KEYS,
  supportedCountryList,
} from "@constants";
import styles from "./PhoneCountrySelect.module.scss";
import DownArrow from "@public/icons/select-down-arrow.svg";
import size from "lodash/size";
import * as storage from "@utils/storage";
import Image from "next/legacy/image";
import { Country } from "react-phone-number-input";

interface IPhoneCountrySelectProps {
  value?: string;
  onChange: (newCountryCode?: Country) => void;
}

const PhoneCountrySelect = ({
  value = "US",
  onChange,
}: IPhoneCountrySelectProps): ReactElement => {
  const [disableSelect, setDisableSelect] = useState<boolean>(false);
  const [countryList, setCountryList] =
    useState<ICountrySelectData[]>(supportedCountryList);

  useEffect(() => {
    setCountryList(
      process.env.NEXT_PUBLIC_DEPLOY_ENV !== "production" ||
        storage?.local?.getItem(LOCAL_STORAGE_KEYS.TEST_IND)
        ? allCountryList
        : supportedCountryList,
    );
  }, []);

  useEffect(() => {
    setDisableSelect(size(countryList || []) <= 1);
  }, [countryList]);

  return (
    <span className={styles["phone-number-country-wrapper"]}>
      <select
        value={value}
        onChange={(event) =>
          !disableSelect &&
          onChange((event?.target?.value || undefined) as Country)
        }
        className={styles["phone-number-country-select"]}
        disabled={disableSelect}
      >
        {(countryList || []).map((c) => (
          <option key={c.value} value={c.value}>
            {c.name}
          </option>
        ))}
      </select>
      {!disableSelect ? (
        <Image
          src={DownArrow}
          alt="select phone number country"
          className={styles["phone-number-country-select-arrow"]}
        />
      ) : null}
    </span>
  );
};

export default PhoneCountrySelect;
