import React, { ReactElement } from "react";
import { DefaultInputComponentProps, Country } from "react-phone-number-input";
import PhoneInputComp from "react-phone-number-input/input";
import styles from "./PhoneInput.module.scss";

interface IPhoneInputProps {
  country?: Country;
  value?: string;
  onChange: (value?: string) => void;
  additionalClassName?: string;
  autoFocus?: DefaultInputComponentProps["autoFocus"];
  placeholder?: string;
  phoneInputProps?: Partial<DefaultInputComponentProps>;
  disabled?: boolean;
}

const PhoneInput: React.FC<IPhoneInputProps> = ({
  country = "US",
  value = "",
  onChange,
  additionalClassName = "",
  autoFocus = true,
  phoneInputProps = {},
  disabled = false, // TODO disabling this does not actually do anything
}): ReactElement => {
  return (
    <>
      <PhoneInputComp
        country={country}
        value={value}
        international={false}
        className={`${additionalClassName} ${styles["phone-input-main"]}`}
        autoFocus={autoFocus}
        autoComplete="tel-national"
        id="abridge-phone-input-main"
        name="abridge-phone-input-tel"
        disabled={disabled}
        {...phoneInputProps}
        onChange={onChange}
      />
    </>
  );
};

export default PhoneInput;
