import React, { ReactElement } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger, { OverlayDelay } from "react-bootstrap/OverlayTrigger";
import InfoIcon from "@public/icons/info-carbon.svg";
import styles from "./InfoTooltip.module.scss";
import { Placement } from "react-overlays/usePopper";
import { SimpleObject } from "@types_";
import Image from "next/legacy/image";
import { clsx } from "clsx";

export interface InfoTooltipProps {
  position?: Placement;
  infoText: string | JSX.Element;
  /**
   * Renders a ? icon only instead of children elements
   */
  onlyShowImage?: boolean;
  /**
   * Delay settings for showing/hiding the tooltip
   */
  delay?: OverlayDelay;

  variant?: "default" | "primary";
}

/**
 * Abridge InfoTooltip UI component
 */
const InfoTooltip: React.FC<InfoTooltipProps> = ({
  position = "bottom",
  infoText = "",
  onlyShowImage = false,
  children,
  delay = {
    show: 200,
    hide: 0,
  },
  variant = "default",
}) => {
  const renderTooltip = (props: SimpleObject = {}) => (
    <Tooltip
      id={`info-tooltip${variant !== "default" ? `-${variant}` : ``}`}
      className={clsx(styles["info-tooltip"], {
        [styles["variant-primary"]]: variant === "primary",
      })}
      {...props}
    >
      {infoText}
    </Tooltip>
  );
  if (!infoText) return children as ReactElement;
  return (
    <OverlayTrigger placement={position} overlay={renderTooltip} delay={delay}>
      {onlyShowImage ? (
        <Image src={InfoIcon} alt="info" className={styles["info-icon"]} />
      ) : (
        (children as ReactElement)
      )}
    </OverlayTrigger>
  );
};

export default InfoTooltip;
