import { clsx } from "clsx";
import styles from "./LoginInputBubble.module.scss";

export const LoginInputBubble: React.FC<{ additionalClassName?: string }> = ({
  children,
  additionalClassName = "",
}) => {
  return (
    <div className={clsx(styles["sign-in-input-bubble"], additionalClassName)}>
      {children}
    </div>
  );
};
