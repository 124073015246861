import { useState, useEffect } from "react";

const useTimer = (
  onTimerEnd: () => void,
): [number | null, (secs: number) => void] => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  const startTimer = (secs: number) => setTimeLeft(secs);

  useEffect(() => {
    if (timeLeft === 0) {
      if (onTimerEnd && typeof onTimerEnd === "function") {
        onTimerEnd();
      }
      setTimeLeft(null);
    }
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      if (timeLeft && typeof timeLeft === "number") {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, onTimerEnd]);

  return [timeLeft, startTimer];
};

export default useTimer;
